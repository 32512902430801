import { db } from './firebase'

import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  query,
  where,
  onSnapshot,
  setDoc
} from "firebase/firestore";

const COLLECTION = 'users'

const col = collection(db, COLLECTION);
let q = query(col)

export const getUsers = async () => {
  const snap = await getDocs(col);
  const list = snap.docs.map((doc) => (doc.data()));
  return list;
};

export const getUserByIds = async (ids) => { 
  q = query(col, where("id", 'in', ids))
    
  const snap = await getDocs(q);
  const list = snap.docs.map((doc) => (doc.data()));
  return list;
}


export const getUserByQuery = async (search = '') => {
  
  // let q
  if (search) {
    q = query(col, where("email", '==', search))
  } else {
    q = query(col)
  }
  
  const snap = await getDocs(q);
  const list = snap.docs.map((doc) => (doc.data()));
  return list;
};

export const unsubscribe = (callback) => onSnapshot(q, (snapshot) => {
  snapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
      // console.log("New city: ", change.doc.data());
    }
    if (change.type === "modified") {
      // console.log("Modified city: ", change.doc.data());
      callback(change.doc.data())
    }
    if (change.type === "removed") {
      // console.log("Removed city: ", change.doc.data()
    }
  })
})


export const updateInitStatusConnection = async (id, connId, onSuccess, onError) => {
  const ref = doc(db, COLLECTION, id);
  return updateDoc(ref, { connectionId: connId }).then((res) => {
    if (onSuccess) {
      onSuccess()
    }
  }).catch((err) => {
    console.log('erro', err);
    onError && onError()
  })
}

export const updateUserStatus = async (id, status) => {
  const ref = doc(db, COLLECTION, id);
  await updateDoc(ref, {status})
}

export const updateUserInfo = async (id, user) => {
  await setDoc(doc(db, COLLECTION, String(id)), user, {
    merge: true,
  });
}


export const getUserInfor = async (userId) => {
  const ref = doc(db, COLLECTION, userId)
  const snap = await getDoc(ref)
  return snap.data()
}

export const getUserByEmail = async (email) => {
  q = query(col, where("email", '==', email))
  const snap = await getDocs(q);
  const list = snap.docs.map((doc) => (doc.data()));
  return list[0];
}